@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'Montserrat-Medium';
    src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
}

@font-face {
    font-family: 'Montserrat-Italic';
    src: url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200&display=swap');
}